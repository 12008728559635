<template>
	<div>
  <LoadingScreen v-if="loading_screen" />
	<ContentHeader title="Data Karyawan" />
     <div class="content-header">
		<div class="container-fluid">
			<div class="card">
              <div class="card-header row align-items-center">
                <h3 class="card-title col-6">Data Karyawan</h3>
                <div class="col-6 text-right">
                  <router-link to="/tambah-data-karyawan"><button class="btn btn-sm btn-info">Tambah</button></router-link> 
                </div>
              </div>
              <div class="card-body" style="display: block;">
                <div class="row align-items-center">
                  <div class="col-lg-2">
                    <span>Jumlah Baris</span>
                    <select class="custom-select form-control" v-model="page" @change="getDataResult">
                      <option value="10">10</option>
                      <option value="15">15</option>
                      <option value="20">20</option>
                      <option value="25">25</option>
                      <option value="30">30</option>
                      <option value="40">40</option>
                      <option value="45">45</option>
                      <option value="50">50</option>
                    </select>
                  </div>
                  <div class="col-lg-6">
                  </div>
                  <div class="col-lg-4">
                      <label class="mt-1">Cari:</label>
                      <input type="text" class="form-control form-control-sm ml-2">                    
                  </div>
                </div>
                <table class="table table-bordered table-hover mt-4">
                  <thead>
                    <tr>
                      <th scope="col" class="text-sm">NAMA LENGKAP</th>
                      <th scope="col" class="text-sm">NOMOR HANDPHONE</th>
                      <th scope="col" class="text-sm">EMAIL</th>
                      <th scope="col" class="text-sm">TEMPAT TANGGAL LAHIR</th>
                      <th scope="col" class="text-sm">JENIS KELAMIN</th>
                      <th scope="col" class="text-sm">STATUS</th>
                      <th scope="col" class="text-sm">AGAMA</th>
                      <th scope="col" class="text-sm">ACTION</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="row in data_result" :key="row.id">
                      <td class="text-sm" scope="row">{{ row.nama_lengkap }}</td>
                      <td class="text-sm">{{ row.nomor_handphone }}</td>
                      <td class="text-sm">{{ row.email }}</td>
                      <td class="text-sm">{{ row.tempat_lahir }}, {{ row.tanggal_lahir }}</td>
                      <td class="text-sm">{{ row.jenis_kelamin }}</td>
                      <td class="text-sm">{{ row.status }}</td>
                      <td class="text-sm">{{ row.agama }}</td>
                      <td class="text-sm">
                        <div class="btn-group">
                          <button type="button" class="btn btn-sm btn-info text-sm">Proses</button>
                          <button type="button" class="btn btn-sm btn-info dropdown-toggle dropdown-icon" data-toggle="dropdown" aria-expanded="false">
                            <span class="sr-only"></span>
                          </button>
                          <div class="dropdown-menu" role="menu" style="">
                            <router-link :to="`/edit-data-karyawan/${row.id}`" class="dropdown-item text-sm">Edit</router-link>
                            <div v-if="level_jabatan != 'staff'">
                              <div class="dropdown-divider"></div>
                              <button @click="konfirmasiHapus(row.id)" class="dropdown-item text-danger">Hapus</button>
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <p class="text-center mt-2" v-if="memuat_data">Memuat Data...</p>
                <p class="text-center mt-2" v-if="cek_data">Data Masih Kosong</p>
              </div>
              <!-- /.card-body -->
              <div class="card-footer">
                <nav aria-label="...">
                  <ul class="pagination">
                    <li class="page-item" :class="{ disabled: pagination.current_page == pagination.from}" @click="paginateData(pagination.prev_page_url)">
                      <a class="page-link" href="#" tabindex="-1" aria-disabled="true">Previous</a>
                    </li>
                    <li class="page-item" :class="{ active: pagination.current_page == row.label }" @click="paginateData(row.url)" v-for="(row, index) in links" :key="index"><a class="page-link" href="#">{{ row.label }}</a></li>
                    <li class="page-item" :class="{ disabled: pagination.current_page == pagination.last_page}" @click="paginateData(pagination.next_page_url)">
                      <a class="page-link" href="#">Next</a>
                    </li>
                  </ul>
                </nav>
              </div>
              <!-- /.card-footer-->
            </div>
		</div>
	</div>
    </div>
</template>

<script setup>
import ContentHeader from '@/components/ContentHeader'

import { ref, computed, onMounted } from 'vue'
import store from '@/store'
import axios from 'axios'
import Swal from 'sweetalert2'
import LoadingScreen from '@/components/LoadingScreen'

const user = computed(() => store.getters['auth/user'])
    const level_jabatan = computed(() => store.getters['auth/level_jabatan'])
    const data_result = ref([])
    const cek_data = ref()
    const page = ref(10)
    const links = ref([])
    const pagination = ref([])
    const memuat_data = ref(false)
    const loading_screen = ref(false)

    const getDataResult = async () => {
    data_result.value = []
    memuat_data.value = true
    loading_screen.value = true

    let { data } = await axios.get(`api/karyawan/get_data/${user.value.cabang_id}/${page.value}`)

    if (data != 'kosong') {
      memuat_data.value = false
      cek_data.value = false
      data_result.value = data.data
      links.value = data.links
      links.value.splice(0, 1)
      links.value.splice(links.value.length-1, 1)
      pagination.value = data
      loading_screen.value = false
    } else {
      cek_data.value = true
      memuat_data.value = false
      data_result.value = []
      loading_screen.value = false
    }
  }

  const paginateData = async (url) => {

      if (url != null) {
        data_result.value = []
        memuat_data.value = true

        let link_url = new URL(url)
        link_url = `${link_url.pathname}${link_url.search}`
        
        let { data } = await axios.get(`${link_url}`)

        
        if (data != 'kosong') {
          memuat_data.value = false
          data_result.value = data.data 
          links.value = data.links
          links.value.splice(0, 1)
          links.value.splice(links.value.length-1, 1)
          pagination.value = data
        }
      }
    }

  const konfirmasiHapus = (id) => {
      Swal.fire({
        title: 'Hapus Data?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: `Ya`,
        cancelButtonText: `Batal`,
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          hapusData(id)
        } 
      })
    }

    const hapusData = (id) => {
        loading_screen.value = true
        axios.delete(`api/karyawan/destroy/${id}`)
        .then((response) => {
          Swal.fire({
            title: 'Berhasil!',
            text: 'Berhasil Menghapus Data',
            icon: 'success',
            showConfirmButton: false,
            timer: 1500
          })
          getDataResult()
          console.log(response.data)
        })
        .catch((error) => {
            Swal.fire({
            title: 'Gagal!',
            text: 'Gagal menghapus data',
            icon: 'error',
            showConfirmButton: false,
            timer: 1500
          })
          console.log(error)
        })
      }

  onMounted(() => {
    getDataResult()
  })
</script>